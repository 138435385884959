var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 pa-3",
        attrs: {
          headers: _vm.headers,
          items: _vm.titulos,
          "item-text": "titulo",
          options: _vm.options,
          loading: _vm.loading,
          "single-select": false,
          "item-key": "id_titulo",
          "show-select": "",
          "footer-props": {
            itemsPerPageOptions: [300, 600, 900],
            itemsPerPageText: "Itens por página:"
          },
          "server-items-length": _vm.totalTitulos,
          "loading-text": "Pesquisando títulos...",
          "no-data-text": "Preencha os filtros e clique em filtrar...",
          "no-results-text": "Nenhum título encontrado..."
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.id_titulo`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.id_titulo))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.id_titulo))
                      ])
                ]
              }
            },
            {
              key: `item.ds_nosso_numero`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.ds_nosso_numero))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.ds_nosso_numero))
                      ])
                ]
              }
            },
            {
              key: `item.dt_cadastro`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.dt_cadastro))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.dt_cadastro))
                      ])
                ]
              }
            },
            {
              key: `item.dt_vencimento`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(_vm.formatDataVencimento(item)))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(_vm.formatDataVencimento(item)))
                      ])
                ]
              }
            },
            {
              key: `item.nu_identificacao_devedor`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(_vm.formatDocumento(item)))
                      ])
                ]
              }
            },
            {
              key: `item.nm_devedor`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.nm_devedor))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.nm_devedor))
                      ])
                ]
              }
            },
            {
              key: `item.vl_saldo_protesto`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.vl_saldo_protesto))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.vl_saldo_protesto))
                      ])
                ]
              }
            },
            {
              key: `item.cd_status`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(_vm.getDescSituacao(item)))
                      ])
                ]
              }
            },
            {
              key: `item.dt_atualizacao`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.dt_atualizacao))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.dt_atualizacao))
                      ])
                ]
              }
            },
            {
              key: `item.cd_uf_devedor`,
              fn: function({ item }) {
                return [
                  item.ds_revisao != ""
                    ? _c(
                        "span",
                        {
                          style: { color: "red" },
                          attrs: { title: "Este título precisa ser revisado. " }
                        },
                        [_vm._v(_vm._s(item.cd_uf_devedor))]
                      )
                    : _c("span", { style: { color: "black" } }, [
                        _vm._v(_vm._s(item.cd_uf_devedor))
                      ])
                ]
              }
            },
            {
              key: `item.ds_agrupador`,
              fn: function({ item }) {
                return [
                  _c("span", { style: { color: "black" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.getDescritivoAgrupador(
                          _vm.$store,
                          item.ds_agrupador
                        )
                      )
                    )
                  ])
                ]
              }
            },
            {
              key: `item.oferta`,
              fn: function({ item }) {
                return [
                  _c(
                    "v-icon",
                    { style: { color: item.oferta > 0 ? "green" : "red" } },
                    [_vm._v(" " + _vm._s(_vm.getBolean(item.oferta)))]
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selecionados,
          callback: function($$v) {
            _vm.selecionados = $$v
          },
          expression: "selecionados"
        }
      }),
      _vm.feedbackSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.feedbackSnackbar,
              corSnackbar: _vm.feedbackSnackbarColor,
              mensagemSnackbar: _vm.mensagemfeedbackSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.feedbackSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }